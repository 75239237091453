import {decode, encode} from "./json-encoder";

export function store<T>(item: string, data: T, localStored: boolean = false, performEncode: boolean = true) {
    // Prepare data for storage
    let saveable = "";
    if (performEncode) {
        saveable = encode(data);
    }

    localStored
        ? window.localStorage.setItem(item, saveable)
        : window.sessionStorage.setItem(item, saveable);
}

export function storeRaw(item: string, data: string, localStored: boolean = false) {
    localStored
        ? window.localStorage.setItem(item, data)
        : window.sessionStorage.setItem(item, data);
}

export function retrieve<T>(item: string, localStored: boolean = false): T | null {
    let mem = retrieveRaw(item, localStored);
    return decode<T>(mem);
}

export function retrieveRaw(item: string, localStored: boolean = false): string | null {
    let mem: string | null = null;
    if (localStored) {
        mem = window.localStorage.getItem(item);
    } else {
        mem = window.sessionStorage.getItem(item);
    }
    return mem;
}
