import {getConfig} from "../../config/web-config";
import domEvent from "../../utils/dom-event";
import {isScriptAllowed} from "../../cmp/is-script-allowed";
import {SCRIPT_FB_PIXEL} from "../../cmp/script-list";

interface FbqObject {
    callMethod: any,
    queue: any,
    push: any,
    loaded: any,
    version: any,
}

export const FB_PIXEL_LOADED = "fbPixel:loaded";

export function initFbPixel() {
    const config = getConfig();
    if (config.fbPixel) {
        // @ts-ignore
        !(function (f: Window, b: Document, e: string, v: string, n: FbqObject, t: any, s: any) {
            if (f.fbq) return;
            // @ts-ignore
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/cs_CZ/fbevents.js");

        // According to https://developers.facebook.com/docs/facebook-pixel/implementation/gdpr/
        if (isScriptAllowed(SCRIPT_FB_PIXEL)) {
            activateFbPixel();
        } else {
            deactivateFbPixel();
        }
        window.fbq("init", config.fbPixel);
        window.fbq('track', "PageView");

        domEvent(FB_PIXEL_LOADED);
    }
}

export function activateFbPixel() {
    if (window.fbq) {
        window.fbq('consent', 'grant');
    }
}

export function deactivateFbPixel() {
    if (window.fbq) {
        window.fbq('consent', 'revoke');
    }
}
