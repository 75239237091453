import {getConfig} from "../../config/web-config";
import {loadScript} from "../../utils/toolkit";

// Puts gtag on window so that it can be used before script loading
export function registerGtag() {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
        window.dataLayer?.push(arguments);
    };
}

export function loadGtag() {
    if (window.gtagLoaded) return;
    window.gtagLoaded = true;
    const config = getConfig();

    if (config.ga4 || config.gAds) {
        loadScript("https://www.googletagmanager.com/gtag/js?id=" + (config.ga4 || config.gAds));

        const _gtag = window.gtag;

        _gtag('consent', 'default', {
            ad_storage: "denied",
            analytics_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            wait_for_update: 500,
        });

        _gtag('js', new Date());

        if (config.ga4) {
            _gtag('config', config.ga4);
        }
        if (config.gAds) {
            _gtag('config', config.gAds, {'allow_enhanced_conversions': true});
        }
    }
}

export function unloadGtag() {
    updateGtagConsent({
        ad_storage: "denied",
        analytics_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
    })
}

export function activateGtagAnalytics() {
    updateGtagConsent({
        analytics_storage: "granted",
    })
}

export function activateGtagRemarketing() {
    updateGtagConsent({
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
    })
}

export function deactivateGtagRemarketing() {
    updateGtagConsent({
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
    })
}

type DeniedOrGranted = "denied" | "granted";

interface GtagConsentSetting {
    ad_storage?: DeniedOrGranted,
    analytics_storage?: DeniedOrGranted,
    ad_user_data?: DeniedOrGranted,
    ad_personalization?: DeniedOrGranted,
}

function updateGtagConsent(setting: GtagConsentSetting): void {
    window.gtag('consent', 'update', setting);
}
