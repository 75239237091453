import {CookieCategory} from "../model/CookieCategory";

export const COOKIE_SETTING_STORAGE = "cookiesSetting";
export const COOKIE_CONSENT_STORAGE = "cookiesConsent";
export const NECESSARY_COOKIES_CATEGORY: CookieCategory = "technické";
export const STATISTICS_COOKIES_CATEGORY: CookieCategory = "statistické";
export const MARKETING_COOKIES_CATEGORY: CookieCategory = "marketingové";
export const PREFERENCE_COOKIES_CATEGORY: CookieCategory = "preferenční";

export const COOKIES_SET_STORAGE = "cookiesSet";
export const SHOW_COOKIE_DISCLAIMER_EVENT = "showCookiesDisclaimer";
