export const SCRIPT_GOOGLE_ANALYTICS_BASE = "ga";
export const SCRIPT_GOOGLE_REMARKETING = "google-remarketing";
export const SCRIPT_FB_PIXEL = "facebook-pixel";
export const SCRIPT_MAILKIT = "mailkit";
export const SCRIPT_MAILKIT_CONVERSION = "mailkit-konverze";
export const SCRIPT_SEZNAM_CONVERSION = "seznam-konverze";
export const SCRIPT_SEZNAM_RETARGETING = "seznam-retargeting";
export const SCRIPT_BING = "bing";
export const SCRIPT_ECOMAIL = "ecomail";
export const SCRIPT_EHUB = "ehub";
export const SCRIPT_HOTJAR = "hotjar";
export const SCRIPT_SMARTSUPP = "smartsupp";
