import {CookieProviderType} from "../model/CookieProviderType";
import {NECESSARY_COOKIES_CATEGORY} from "../config/Constants";

export default function checkAllCookiesAccepted(consent: Record<string, boolean> | null, providers: CookieProviderType[]): boolean {
    if (!consent) {
        return false;
    }
    // Optimized oneliner.
    // Tries to find a provider whose script is not part of the consent and also is not necessary (necessary don't need the consent so they are not part of it)
    // If there IS a provider with missing consent, then all scripts are NOT accepted, therefore flip the bool
    return !providers.some(provider => {
        return provider.scripts.some(script => {
            // Returns true if there is some missing consent for an unnecessary script
            return script.category !== NECESSARY_COOKIES_CATEGORY && !consent[script.slug]
        })
    });
}
