import { CookieProviderType } from "../model/CookieProviderType";
import error from "../analytics/tracking/error";
import { API_COOKIES_INFO } from "../config/ApiRoutes";
import { retrieve, store } from "../utils/browser-store";
import { COOKIE_SETTING_STORAGE } from "../config/Constants";
import { getConfig } from "../config/web-config";

export async function fetchCookiesSetting(): Promise<CookieProviderType[]> {
    try {
        let savedSetting = retrieve<CookieProviderType[] | null>(COOKIE_SETTING_STORAGE, false);

        // if saved setting is not an array, delete it
        if (savedSetting && !Array.isArray(savedSetting)) {
            store<null>(COOKIE_SETTING_STORAGE, null, false);
            savedSetting = null;
        }

        if (savedSetting) {
            return savedSetting;
        } else {
            const response = await fetch(API_COOKIES_INFO + getConfig().websiteSlug);
            if (!response.ok) {
                error("Fetch cookies info failed request");
            }
            const cookiesInfo: CookieProviderType[] = await response.json();
            store<CookieProviderType[]>(COOKIE_SETTING_STORAGE, cookiesInfo, false);
            return cookiesInfo;
        }
    } catch (err) {
        error("Fetch cookies info failed process");
        throw err;  // re-throw the error after logging it
    }
}
