<style src="./cookies.css" scoped></style>

<template>
    <div class="tw-border-b tw-border-white/15 [&:not(:first-child)]:tw-mt-4">
        <div class="tw-flex tw-items-center tw-flex-row tw-w-full tw-gap-2 tw-border tw-border-gray-300 tw-bg-gray-100 tw-pl-2 tw-pr-2 tw-py-2 tw-rounded-t-3xl" :class="showScripts ? '' : 'tw-rounded-b-3xl'">
            <button type="button" class="tw-w-6 tw-h-6 tw-bg-gray-300 tw-rounded-full tw-cursor-pointer tw-[-webkit-tap-highlight-color:transparent] tw-flex tw-items-center tw-justify-center" @click="showScripts = !showScripts">
                <svg viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tw-p-1" :class="showScripts ? 'tw-rotate-180' : ''">
                    <polyline points="6 9 12 15 18 9"/>
                </svg>
            </button>
            <div class="tw-font-semibold tw-text-lg">{{ provider.companyName }}</div>
            <div v-if="provider.personalUrl" class="">
                <a :href="provider.personalUrl" class="tw-underline tw-text-xs">
                    více informací
                </a>
            </div>
            <input class="tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 focus:tw-ring-2 tw-ml-auto tw-cursor-pointer tw-ml-auto" type="checkbox" v-model="wholeProvider" :disabled="allScriptsNecessary">
        </div>
        <div class="tw-border-x tw-border-b tw-rounded-b-3xl tw-border-gray-300 tw-overflow-hidden" v-for="script in provider.scripts" v-show="showScripts">
            <div class="tw-flex tw-flex-wrap tw-font-bold tw-items-center tw-justify-between tw-px-2 tw-py-2">
                <span class="tw-text-[1.2em]">{{ script.name }}</span>
                <div class="tw-ml-auto">
                    <input type="checkbox" class="tw-w-3 tw-h-3 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 focus:tw-ring-2 tw-ml-auto tw-cursor-pointer" v-model="script.active" :disabled="script.category === NECESSARY_COOKIES_CATEGORY">
                </div>
            </div>
            <div class="tw-flex even:tw-bg-white odd:tw-bg-gray-100 tw-px-2 tw-py-2 tw-relative" v-for="cookie in script.cookies">
                <div class="tw-flex-grow">
                    <ul class="tw-text-sm">
                        <li><span class="tw-mr-1 tw-inline-block tw-font-semibold">Název: </span><span>{{ cookie.name }}</span></li>
                        <li><span class="tw-mr-1 tw-inline-block tw-font-semibold">Popis: </span><span>{{ cookie.description }}</span></li>
                        <li><span class="tw-mr-1 tw-inline-block tw-font-semibold">Typ: </span><span>{{ cookie.type }}</span></li>
                        <li><span class="tw-mr-1 tw-inline-block tw-font-semibold">Kategorie: </span><span>{{cookie.category }}</span></li>
                        <li><span class="tw-mr-1 tw-inline-block tw-font-semibold">Expirace: </span><span>{{cookie.expiration }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup lang="ts">
import {computed, PropType, Ref, ref, WritableComputedRef} from "vue";
import {CookieProviderType} from "../../model/CookieProviderType";
import {NECESSARY_COOKIES_CATEGORY} from "../../config/Constants";
import {ScriptType} from "../../model/ScriptType";

const props = defineProps({
    provider: {
        type: Object as PropType<CookieProviderType>,
        required: true
    }
});

const emit = defineEmits(["toggleProvider"]);

const wholeProvider: WritableComputedRef<boolean> = computed({
    get(): boolean {
        return areAllChecked(props.provider);
    },
    set(value: boolean): void {
        emit("toggleProvider", {provider: props.provider.companyName, newVal: value});
    },
});

const showScripts: Ref<boolean> = ref(false);

const allScriptsNecessary = computed(() => {
    return !props.provider.scripts.some(script => {
        return script.category !== NECESSARY_COOKIES_CATEGORY;
    })
});

function toggleShowCookies(script: ScriptType) {
    script.showingCookies = !script.showingCookies;
}

function areAllChecked(provider: CookieProviderType): boolean {
    return !provider.scripts.some(script => {
        return !script.active;
    });
}

</script>
