import {getConfig} from "../../config/web-config";
import loadSeznamScript, {SEZNAM_SCRIPT_ELEMENT, SEZNAM_SCRIPT_EVENT} from "./seznam";
import {isScriptAllowed} from "../../cmp/is-script-allowed";
import {SCRIPT_SEZNAM_RETARGETING} from "../../cmp/script-list";

export function loadSeznamRetargeting() {
    const config = getConfig();

    if (config.seznamRetargeting) {
        if (document.getElementById(SEZNAM_SCRIPT_ELEMENT)) {
            if (window["rc"] && window["rc"].retargetingHit) { // If its already loaded
                sendRetargetingInfo()
            } else {
                document.addEventListener(SEZNAM_SCRIPT_EVENT, () => sendRetargetingInfo());
            }
        } else {
            loadSeznamScript(sendRetargetingInfo)
        }
    }
}

function sendRetargetingInfo() {
    const config = getConfig();
    const retargetingConf = {
        rtgId: config.seznamRetargeting,
        pageType: "offerdetail",
        consent: isScriptAllowed(SCRIPT_SEZNAM_RETARGETING) ? 1 : 0,
    };

    window["rc"].retargetingHit(retargetingConf);
}
