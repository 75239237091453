import {retrieve, store} from "../utils/browser-store";
import ConsentType from "../model/ConsentType";
import {COOKIE_CONSENT_STORAGE} from "../config/Constants";
import {CookieProviderType} from "../model/CookieProviderType";
import {deleteAllCookies} from "./cookies-operations";

export function giveConsent(setting: Record<string, boolean>, source: string) {
    saveConsent(setting); // Keeps the setting in browser storage
}

export function createSettingFromProviders(providers: CookieProviderType[]): Record<string, boolean> {
    // This makes {a: true, b: false} from [{cookies: [{slug: "a", active: true}]},{cookies: [{slug: "b", active: false}]}]
    return Object.fromEntries(providers.flatMap(provider => provider.scripts.map(script => [script.slug, script.active || false])));
}

/**
 * The opposite of consent. The user made an action to deny the conditions of data collection.
 */
export function denyConsent(source: string) {
    giveConsent({}, source);
    deleteAllCookies();
}

export function getSavedConsent(): ConsentType | null {
    let savedConsent = retrieve<ConsentType | null>(COOKIE_CONSENT_STORAGE, true);
    return savedConsent || null;
}

function saveConsent(setting: Record<string, boolean>) {
    store(COOKIE_CONSENT_STORAGE, {setting, savedAt: new Date().getTime()} as ConsentType, true);
}
