export function loadScript(url: string, cb?: Function, reloadable?: boolean, elId?: string, async: boolean = true): void {
    let script = document.createElement("script");
    script.type = "text/javascript";
    if (elId) script.id = elId;
    if (!async) script.async = async;

    // In SPA, a part of scripts is being reloaded with each statechange using a reloadables container.
    // This container (div) is emptied with each statechange
    if (reloadable) {
        document.getElementById("reloadables")?.appendChild(script);
    } else {
        document.body.appendChild(script);
    }

    script.onload = function () {
        if (cb) {
            cb();
        }
    };

    script.src = url;
}

export function loadImage(url: string, cb?: Function): void {
    let image = document.createElement("img");

    image.onload = function () {
        if (cb) {
            cb();
        }
    };

    image.src = url;
    document.body.appendChild(image);
}

export function loadIframe(url: string): void {
    let iframe = document.createElement("iframe");
    iframe.src = url;
    document.body.appendChild(iframe);
}

export function qs(key: string): string | null {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    const match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
