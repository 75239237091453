<style src="./cookies.css" scoped></style>

<template>
    <div id="cookie-disclaimer"
         class="tw-fixed tw-flex tw-items-center tw-justify-center tw-inset-0 tw-bg-gray-800/30 tw-z-[1000] tw-text-[16px] tw-text-gray-700 tw-max-h-screen tw-mx-2"
         v-if="showDisclaimer">
        <div class="tw-bg-white tw-rounded tw-max-w-[800px] tw-w-full tw-p-4">
            <div class="tw-grid tw-grid-cols-3 tw-gap-4">
                <div class="md:tw-col-span-2 tw-col-span-3">
                    <div>
                        <h2 class="tw-font-semibold tw-text-lg tw-mb-2">Záleží nám na Vašem soukromí</h2>
                        <p class="tw-mb-0">
                            Na našich webových stránkách využíváme cookies, které nám umožňují správnou funkci stránek
                            na Vašem zařízení, můžeme díky nim zvyšovat kvalitu našich služeb a sortimentu a
                            přizpůsobovat nabídky, aby pro Vás byly relevantní. Rozsah zpracování si můžete libovolně
                            nastavit.
                        </p>
                    </div>
                </div>
                <div class="md:tw-col-span-1 tw-col-span-3 tw-flex tw-justify-center">
                    <div
                        class="tw-flex tw-flex-col sm:tw-flex-row md:tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-w-full">
                        <button
                            class="tw-bg-gray-700 hover:tw-bg-gray-800 tw-transition-colors tw-duration-500 tw-text-white tw-px-4 tw-py-1.5 tw-rounded tw-text-center tw-w-full"
                            @click="acceptAll">
                            Souhlasím
                        </button>
                        <button
                            class="tw-bg-gray-200 hover:tw-bg-gray-300 tw-transition-colors tw-duration-500 tw-text-gray-700 tw-px-4 tw-py-1.5 tw-rounded tw-text-center tw-w-full"
                            @click="showDetails = !showDetails">
                            Přizpůsobit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showDisclaimer && showDetails"
         class="tw-fixed tw-flex tw-items-center tw-justify-center tw-inset-0 tw-bg-gray-800/30 tw-z-[1100] tw-text-[16px] tw-text-gray-700 tw-mx-2">
        <div
            class="tw-bg-white tw-rounded tw-max-w-[700px] tw-w-full tw-p-4 tw-flex tw-flex-col tw-gap-6 tw-max-h-[100dvh] tw-overflow-auto">
            <div class="tw-flex tw-flex-row tw-gap-4 tw-justify-between">
                <h2 class="tw-inline-block tw-text-lg tw-mb-0 tw-font-semibold">
                    Nastavení cookies
                </h2>
                <button type="button"
                        class="tw-bg-gray-200 tw-transition-colors tw-duration-500 hover:tw-bg-gray-300 tw-p-2 tw-rounded"
                        @click="showDetails = !showDetails">
                    <svg viewBox="0 0 24 24" stroke-width="1.5" class="tw-w-3.5 tw-h-3.5 tw-stroke-gray-700">
                        <path d="M 19.5 4.5 L 4.5 19.5 M 4.5 4.501 L 19.5 19.5"></path>
                    </svg>
                </button>
            </div>
            <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-2">
                <div
                    class="tw-flex tw-flex-row tw-w-full tw-gap-4 tw-items-center tw-justify-between tw-border tw-bg-gray-100 tw-pl-6 tw-pr-2 tw-py-1.5 tw-rounded-full">
                    <span>Technické</span>
                    <label class="tw-inline-flex tw-select-none tw-mb-0">
                        <input class="tw-sr-only tw-peer tw-mb-0" type="checkbox" disabled checked>
                        <div
                            class="tw-relative tw-select-none tw-w-11 tw-h-6 tw-bg-gray-300 tw-rounded-full peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-absolute after:tw-top-0.5 after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-gray-300"></div>
                    </label>
                </div>
                <div
                    class="tw-flex tw-flex-row tw-w-full tw-gap-4 tw-items-center tw-justify-between tw-border tw-bg-gray-100 tw-pl-6 tw-pr-2 tw-py-1.5 tw-rounded-full">
                    <span>Statistické</span>
                    <label class="tw-inline-flex tw-cursor-pointer [-webkit-tap-highlight-color:transparent] tw-mb-0">
                        <input class="tw-sr-only tw-peer tw-mb-0" type="checkbox" v-model="statisticsCheckbox">
                        <div
                            class="tw-relative tw-select-none tw-w-11 tw-h-6 tw-bg-gray-300 tw-rounded-full peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-absolute after:tw-top-0.5 after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-blue-500"></div>
                    </label>
                </div>
                <div
                    class="tw-flex tw-flex-row tw-w-full tw-gap-4 tw-items-center tw-justify-between tw-border tw-bg-gray-100 tw-pl-6 tw-pr-2 tw-py-1.5 tw-rounded-full">
                    <span>Preferenční</span>
                    <label class="tw-inline-flex tw-cursor-pointer [-webkit-tap-highlight-color:transparent] tw-mb-0">
                        <input class="tw-sr-only tw-peer tw-mb-0" type="checkbox" v-model="preferenceCheckbox">
                        <div
                            class="tw-relative tw-select-none tw-w-11 tw-h-6 tw-bg-gray-300 tw-rounded-full peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-absolute after:tw-top-0.5 after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-blue-500"></div>
                    </label>
                </div>
                <div
                    class="tw-flex tw-flex-row tw-w-full tw-gap-4 tw-items-center tw-justify-between tw-border tw-bg-gray-100 tw-pl-6 tw-pr-2 tw-py-1.5 tw-rounded-full">
                    <span>Marketingové</span>
                    <label class="tw-inline-flex tw-cursor-pointer [-webkit-tap-highlight-color:transparent] tw-mb-0">
                        <input class="tw-sr-only tw-peer" type="checkbox" v-model="marketingCheckbox">
                        <div
                            class="tw-relative tw-select-none tw-w-11 tw-h-6 tw-bg-gray-300 tw-rounded-full peer-checked:after:tw-translate-x-full peer-checked:after:tw-border-white after:tw-absolute after:tw-top-0.5 after:tw-start-[2px] after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-5 after:tw-w-5 after:tw-transition-all peer-checked:tw-bg-blue-500"></div>
                    </label>
                </div>
            </div>
            <div class="tw-mt-3">
                <div class="tw-flex">
                    <div
                        class="tw-py-1 sm:tw-py-2 tw-px-2 sm:tw-px-3 lg:tw-px-4 tw-text-center tw-cursor-pointer tw-transition tw-duration-300 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-gray-700 tw-translate-y-[1px] [&.tw-active]:tw-bg-blue-500 [&.tw-active]:tw-text-white tw-select-none tw-rounded-tl"
                        :class="{'tw-active': activeTab === 1}" @click="activeTab = 1">
                        <span class="">Použivané cookies</span>
                    </div>
                    <div
                        class="tw-py-1 sm:tw-py-2 tw-px-2 sm:tw-px-3 lg:tw-px-4 tw-text-center tw-cursor-pointer tw-transition tw-duration-300 tw-border tw-border-gray-300 tw-bg-gray-100 tw-text-gray-700 tw-translate-y-[1px] tw-border-l-0 [&.tw-active]:tw-bg-blue-500 [&.tw-active]:tw-text-white tw-select-none tw-rounded-tr"
                        :class="{'tw-active': activeTab === 2}" @click="activeTab = 2">
                        <span class="">Informace o cookies</span>
                    </div>
                </div>
                <div class="tw-border tw-border-gray-300 tw-bg-white tw-overflow-hidden tw-rounded-b tw-rounded-tr">
                    <div class="tw-p-2 sm:tw-p-4 tw-overflow-y-scroll tw-max-h-[248px] tw-h-full"
                         v-show="activeTab === 1">
                        <CookieProvider v-for="provider in providers" :provider="provider"
                                        @toggleProvider="toggleProvider"/>
                    </div>
                    <div class="tw-p-2 sm:tw-p-4 tw-overflow-y-scroll tw-max-h-[248px] tw-h-full tw-text-sm"
                         v-show="activeTab === 2">
                        <p>
                            Cookies jsou malé textové soubory, které mohou být používány webovými stránkami,
                            aby
                            učinily uživatelský zážitek více efektivní.
                        </p>
                        <p>
                            Aby se mohly webové stránky správně zobrazovat, potřebujeme sbírat tzv.
                            technické
                            cookies. Pro všechny ostatní typy cookies potřebujeme váš souhlas.
                        </p>
                        <p>
                            Tato stránka používá různé typy cookies. Některé cookies jsou umístěny službami
                            třetích stran, které se objevují na našich stránkách. Kdykoliv můžete změnit
                            nebo
                            zrušit svůj souhlas prostřednictvím panelu na našich webových stránkách.
                        </p>
                        <p>
                            Zjistěte v našich zásadách ochrany osobních údajů více o tom, kdo jsme, jak nás
                            můžete kontaktovat a jak zpracováváme vaše osobní údaje.
                        </p>
                        <p class="tw-mb-0">
                            Vždy prosím uvádějte ID svého souhlasu a datum, kdy nás budete kontaktovat
                            ohledně
                            vašeho souhlasu.
                        </p>
                    </div>
                </div>
                <div class="tw-mt-4">
                    <div class="tw-flex tw-flex-row tw-justify-between tw-gap-4">
                        <button
                            class="tw-bg-gray-700 hover:tw-bg-gray-800 tw-transition-colors tw-duration-500 tw-text-white tw-px-4 tw-py-1.5 tw-rounded tw-text-center"
                            @click="acceptAll">
                            Přijmout vše
                        </button>

                        <button
                            class="tw-bg-gray-200 hover:tw-bg-gray-300 tw-transition-colors tw-duration-500 tw-text-gray-700 tw-px-4 tw-py-1.5 tw-rounded tw-text-center"
                            @click="acceptSelected">
                            Použít vybrané
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup lang="ts">
import {computed, Ref, ref, WritableComputedRef} from "vue";
import {createSettingFromProviders, denyConsent, getSavedConsent, giveConsent} from "../../cmp/consent";
import checkAllCookiesAccepted from "../../cmp/check-all-accepted";
import {storeRaw, retrieveRaw} from "../../utils/browser-store";
import {CookieProviderType} from "../../model/CookieProviderType";
import {reloadScriptsBySetting} from "../../cmp/tracking-3rd-loader";
import {
    COOKIES_SET_STORAGE,
    MARKETING_COOKIES_CATEGORY,
    NECESSARY_COOKIES_CATEGORY,
    PREFERENCE_COOKIES_CATEGORY,
    SHOW_COOKIE_DISCLAIMER_EVENT,
    STATISTICS_COOKIES_CATEGORY
} from "../../config/Constants";
import {CookieCategory} from "../../model/CookieCategory";
import {fetchCookiesSetting} from "../../cmp/cookie-info";
import CookieProvider from "./CookieProvider.vue";
import {getConfig} from "../../config/web-config";
import {loadGtag} from "../../analytics/tracking-3rd/gtag";

const cookieInfoUrl = getConfig().cookieInfoUrl;

const showDisclaimer: Ref<boolean> = ref(false);
const activeConsent = getSavedConsent()?.setting || null;
const activeTab = ref(1);
const showDetails: Ref<boolean> = ref(false);
const providers: Ref<CookieProviderType[]> = ref([]);

const statisticsCheckbox = computed({
    get(): boolean {
        return areAllChecked(providers.value, STATISTICS_COOKIES_CATEGORY);
    },
    set(value: boolean): void {
        markScripts(value, STATISTICS_COOKIES_CATEGORY);
    },
}) as WritableComputedRef<boolean>;

const preferenceCheckbox = computed({
    get(): boolean {
        return areAllChecked(providers.value, PREFERENCE_COOKIES_CATEGORY);
    },
    set(value: boolean): void {
        markScripts(value, PREFERENCE_COOKIES_CATEGORY);
    },
}) as WritableComputedRef<boolean>;

const marketingCheckbox = computed({
    get(): boolean {
        return areAllChecked(providers.value, MARKETING_COOKIES_CATEGORY);
    },
    set(value: boolean): void {
        markScripts(value, MARKETING_COOKIES_CATEGORY);
    },
}) as WritableComputedRef<boolean>;

loadGtag(); // This is easier to do right away so that we dont run into some race conditions

fetchCookiesSetting()
    .then(data => {
        providers.value = data;
        if (!checkAllCookiesAccepted(activeConsent, data) && !retrieveRaw(COOKIES_SET_STORAGE)) {
            showDisclaimer.value = true;
        }
        markScripts();
        reloadScriptsBySetting();
    });

function acceptAll() {
    showDisclaimer.value = false;
    showDetails.value = false; // Also hide details if open

    // Then do the heavy work
    markScripts(true);

    // Finally do the storage/consent operations
    storeRaw(COOKIES_SET_STORAGE, "true");
    giveConsent(createSettingFromProviders(providers.value), "disclaimer");

    setTimeout(() => {
        reloadScriptsBySetting();
    }, 100);
}

function acceptSelected() {
    showDisclaimer.value = false;

    storeRaw(COOKIES_SET_STORAGE, "true");
    giveConsent(createSettingFromProviders(providers.value), "disclaimer");
    reloadScriptsBySetting();
}

function denyAll() {
    showDisclaimer.value = false;
    storeRaw(COOKIES_SET_STORAGE, "true");
    denyConsent("disclaimer")
}

function markScripts(setToBool?: boolean, category?: CookieCategory, providerName?: string) {
    let hasChanges = false;

    const updatedProviders = providers.value.map(provider => {
        if (!providerName || providerName === provider.companyName) {
            const updatedScripts = provider.scripts.map(script => {
                let newActive = script.active;

                if (setToBool === true || setToBool === false) {
                    if (!category || category === script.category) {
                        newActive = setToBool;
                    }
                } else if (activeConsent) {
                    newActive = activeConsent[script.slug];
                }
                if (script.category === NECESSARY_COOKIES_CATEGORY) {
                    newActive = true;
                }

                if (newActive !== script.active) {
                    hasChanges = true;
                    return { ...script, active: newActive };
                }
                return script;
            });

            return hasChanges ? { ...provider, scripts: updatedScripts } : provider;
        }
        return provider;
    });

    if (hasChanges) {
        providers.value = updatedProviders;
    }
}

document.addEventListener(SHOW_COOKIE_DISCLAIMER_EVENT, () => {
    showDisclaimer.value = true;
});

function toggleProvider(payload: { provider: string, newVal: boolean }) {
    markScripts(payload.newVal, undefined, payload.provider);
}

function areAllChecked(providers: CookieProviderType[], category: string): boolean {
    let hasCategory = false;

    for (const provider of providers) {
        for (const script of provider.scripts) {
            if (script.category === category) {
                hasCategory = true;
                if (!script.active) {
                    return false;  // Early return if we find an inactive script
                }
            }
        }
    }

    return hasCategory;
}
</script>
