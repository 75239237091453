import {getConfig} from "../../config/web-config";

export default function initSmartsupp() {
    const config = getConfig();
    if (config.smartsuppKey) {
        window._smartsupp = window._smartsupp || {};
        window._smartsupp.key = config.smartsuppKey;
        if (!window.smartsupp) {
            (function (d) {
                let s,
                    c,
                    o: any = (window.smartsupp = function () {
                        o._.push(arguments);
                    });
                o._ = [];
                s = d.getElementsByTagName("script")[0];
                c = d.createElement("script");
                c.type = "text/javascript";
                c.charset = "utf-8";
                c.async = true;
                c.src = "https://www.smartsuppchat.com/loader.js?";
                s.parentNode?.insertBefore(c, s);
            })(document);
        }
    }
}
