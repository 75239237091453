import {loadScript} from "../../utils/toolkit";
import domEvent from "../../utils/dom-event";

export const SEZNAM_SCRIPT_ELEMENT = "seznam-script";
export const SEZNAM_SCRIPT_EVENT = "seznam-script-loaded";

export default function loadSeznamScript(cb?: () => any) {
    loadScript("https://c.seznam.cz/js/rc.js", () => {
        domEvent(SEZNAM_SCRIPT_EVENT);
        if (cb) {
            cb();
        }
    }, false, SEZNAM_SCRIPT_ELEMENT);
}
