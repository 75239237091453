export function encode(input: any): string {
    return btoa(unescape(encodeURIComponent(JSON.stringify(input))));
}

export function decode<T>(input: string | null): T | null {
    if (!input) return null;
    let output;
    try {
        output = JSON.parse(decodeURIComponent(escape(atob(input))));
    } catch (e) {
        output = null;
    }
    return output;
}
