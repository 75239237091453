import {getConfig} from "../../config/web-config";

export function loadEcomail() {
    const config = getConfig();
    (function (w, d, s, o, f, js?: any, fjs?: any) {
        w['ecm-widget'] = o;
        // @ts-ignore
        w[o] = w[o] || function () {
            // @ts-ignore
            (w[o].q = w[o].q || []).push(arguments)
        };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = config.ecomailId;
        js.dataset.a = config.ecomailName;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'ecmwidget', 'https://d70shl7vidtft.cloudfront.net/widget.js'));
}
