// @version 2.0
import {API_ERROR} from "../../config/ApiRoutes";
import {getConfig} from "../../config/web-config";

interface ErrorTrace {
    site: string,
    url: string,
    status: number,
    message: string,
    userAgent: string
}

export default function error(message: string, errCode: number = 0): void {
    const data: ErrorTrace = {
        site: getConfig().baseUrl || window.location.hostname,
        url: window.location.href,
        status: errCode,
        message: message,
        userAgent: navigator.userAgent,
    };

    fetch(API_ERROR, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(r => {})
}
