import { scripts } from "./script-list-setting";
import { isScriptAllowed } from "./is-script-allowed";

export function reloadScriptsBySetting() {
    scripts.forEach(script => {
        if (!script.loaded) {
            if (script.always) {
                script.always();
            }
            script.loaded = true;  // Mark as loaded after calling always, not to call it again on this page
        }

        const isAllowed: boolean = isScriptAllowed(script.slug);

        if (script.activated && !isAllowed) {
            if (script.deactivate) {
                script.deactivate();
            }
            script.activated = false;  // Mark as deactivated
        } else if (!script.activated && isAllowed) {
            if (script.activate) {
                script.activate();
            }
            script.activated = true;  // Mark as activated
        }
    });
}
